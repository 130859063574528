.form-header {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-form-header);
  width: 66px;
  height: 66px;
  border-radius: 50%;
}

.icon {
  width: 38px;
  height: 38px;
  color: var(  --empty-main-color);
}

.title {
  margin-top: 14px;
  color:var(  --fourth-text-colorr)
}

.text {
  color: var(--text-number-color);
  font-size: 16px;
}
